const handleApiError = (error, vm, msg) => {
  console.log(error);
  let toastMsg =
    "There was an error processing your request. Please try again later.";
  if (msg) {
    toastMsg = msg;
  }
  vm.$bvToast.toast(toastMsg, {
    title: "Error",
    autoHideDelay: 5000,
    variant: "danger",
  });
};

export default handleApiError;
